import {
    ADD_STORE_REQUEST,
    ADD_STORE_SUCCESS,
    ADD_STORE_ERROR,
    EDIT_STORE_REQUEST,
    EDIT_STORE_SUCCESS,
    EDIT_STORE_ERROR,
    UPDATE_STORE_REQUEST,
    UPDATE_STORE_SUCCESS,
    UPDATE_STORE_ERROR,
    GET_STORE_REQUEST,
    GET_STORE_SUCCESS,
    GET_STORE_ERROR,
    ONCHANGE_STORE_DATA,
    ONCHANGE_ADD_STORE_DATA,
    CHANGE_STORE_CREDENTIAL_REQUEST,
    CHANGE_STORE_CREDENTIAL_SUCCESS,
    CHANGE_STORE_CREDENTIAL_ERROR,
    CLEAR_FLAGS,

} from '../constants/storeConstant';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    storeDataList: [],
    selectStoreData: {},
    addedStoreData: {},
    editOnChange: {},
    addHandleChange: {
        "storeName": "",
        "description": "",
        "address": "",
    },
    message: '',
    addStore: false,

}

const store = (state = initialState, action) => {
    switch (action.type) {
        case GET_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                errorMessage: ''
            }
        case GET_STORE_SUCCESS:
            // const { storeData } = action.payload

            return {
                ...state,
                storeDataList: action.storeData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.message
            }

        case GET_STORE_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }
        case ADD_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                errorMessage: "",
                addStore: false
            }
        case ADD_STORE_SUCCESS:
            const cloneData = state.storeDataList
            cloneData.push(action.userData)
            return {
                ...state,
                addedStoreData: action.userData,
                loading: false,
                storeDataList: cloneData,
                isSuccess: true,
                status: 'successful',
                addStore: true,
                addHandleChange: state.addHandleChange
            }

        case ADD_STORE_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
                addStore: false,
                message: action.errorMsg
            }
        case UPDATE_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                addStore: false
            }
        case UPDATE_STORE_SUCCESS:
            return {
                ...state,
                userDetails: action.userData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                addStore: true
            }

        case UPDATE_STORE_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                addStore: false
            }
        case EDIT_STORE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }
        case EDIT_STORE_SUCCESS:

            return {
                ...state,
                selectStoreData: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }

        case EDIT_STORE_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
            }
        case ONCHANGE_STORE_DATA:

            return {
                ...state,
                selectStoreData: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }

        case ONCHANGE_ADD_STORE_DATA:

            return {
                ...state,
                addHandleChange: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }

        case CHANGE_STORE_CREDENTIAL_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }

        case CHANGE_STORE_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg
            }

        case CHANGE_STORE_CREDENTIAL_ERROR:
            return {
                ...state,
                loading: false,
                status: 'fail',
                message: action.msg
            }
        case CLEAR_FLAGS:
            return {
                ...state,
                addStore: false
            }

        default:
            return state
    }
}
export default store
