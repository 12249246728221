import {
    ADD_GIFT_CARD_CATEGORY_REQUEST,
    ADD_GIFT_CARD_CATEGORY_SUCCESS,
    ADD_GIFT_CARD_CATEGORY_ERROR,
    UPDATE_GIFT_CARD_CATEGORY_REQUEST,
    UPDATE_GIFT_CARD_CATEGORY_SUCCESS,
    UPDATE_GIFT_CARD_CATEGORY_ERROR,
    GET_GIFT_CARD_CATEGORY_REQUEST,
    GET_GIFT_CARD_CATEGORY_SUCCESS,
    GET_GIFT_CARD_CATEGORY_ERROR,
    DELETE_GIFT_CARD_CATEGORY_REQUEST,
    DELETE_GIFT_CARD_CATEGORY_SUCCESS,
    DELETE_GIFT_CARD_CATEGORY_ERROR,
    ADD_GIFT_CARD_REQUEST,
    ADD_GIFT_CARD_SUCCESS,
    ADD_GIFT_CARD_ERROR,
    UPDATE_GIFT_CARD_REQUEST,
    UPDATE_GIFT_CARD_SUCCESS,
    UPDATE_GIFT_CARD_ERROR,
    GET_GIFT_CARD_REQUEST,
    GET_GIFT_CARD_SUCCESS,
    GET_GIFT_CARD_ERROR,
    DELETE_GIFT_CARD_REQUEST,
    DELETE_GIFT_CARD_SUCCESS,
    DELETE_GIFT_CARD_ERROR,
    CLEAR_FLAGS,

} from '../constants/giftCardConstant';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    giftCategoryLists: [],
    selectedGiftCategory: {},
    addedGiftData: {},
    updatedGiftCategory: {},
    message: '',
    closeFlag: false,
    deletedCategory: {},
    giftCardLists: [],
    selectedGiftCard: {},
    addedGiftCard: {},
    updatedGiftCard: {},

}

const giftCard = (state = initialState, action) => {
    switch (action.type) {
        case GET_GIFT_CARD_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                errorMessage: ''
            }
        case GET_GIFT_CARD_CATEGORY_SUCCESS:
            return {
                ...state,
                giftCategoryLists: action.storeData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.message
            }

        case GET_GIFT_CARD_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }
        case ADD_GIFT_CARD_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                errorMessage: "",
                closeFlag: false
            }
        case ADD_GIFT_CARD_CATEGORY_SUCCESS:
            const cloneData = state.giftCategoryLists
            cloneData.push(action.userData)
            return {
                ...state,
                addedGiftData: action.userData,
                loading: false,
                giftCategoryLists: cloneData,
                isSuccess: true,
                status: 'successful',
                closeFlag: true,

            }

        case ADD_GIFT_CARD_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
                closeFlag: false,
                message: action.errorMsg
            }
        case UPDATE_GIFT_CARD_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                closeFlag: false
            }
        case UPDATE_GIFT_CARD_CATEGORY_SUCCESS:
            const cloneGiftCategoryLists = state.giftCategoryLists
            let index = cloneGiftCategoryLists.findIndex(({ _id }) => _id === action.userData._id)
            if (index !== -1) {
                cloneGiftCategoryLists[index] = action.userData
            }
            return {
                ...state,
                userDetails: action.userData,
                giftCategoryLists: cloneGiftCategoryLists,
                loading: false,
                isSuccess: true,
                status: 'successful',
                closeFlag: true
            }

        case UPDATE_GIFT_CARD_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,

                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                closeFlag: false
            }
        case DELETE_GIFT_CARD_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                closeFlag: false
            }
        case DELETE_GIFT_CARD_CATEGORY_SUCCESS: {
            return {
                ...state,
                deletedCategory: action.categoryData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                closeFlag: true,
            }
        }
        case DELETE_GIFT_CARD_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                closeFlag: false
            }
        case CLEAR_FLAGS:
            return {
                ...state,
                closeFlag: false
            }
        case GET_GIFT_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                errorMessage: ''
            }
        case GET_GIFT_CARD_SUCCESS:
            return {
                ...state,
                giftCardLists: action.storeData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.message
            }

        case GET_GIFT_CARD_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }
        case ADD_GIFT_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                errorMessage: "",
                closeFlag: false
            }
        case ADD_GIFT_CARD_SUCCESS:
            const cloneData1 = state.giftCardLists
            cloneData1.push(action.userData)
            return {
                ...state,
                addedGiftCard: action.userData,
                loading: false,
                giftCardLists: cloneData1,
                isSuccess: true,
                status: 'successful',
                closeFlag: true,

            }

        case ADD_GIFT_CARD_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
                closeFlag: false,
                message: action.errorMsg
            }
        case UPDATE_GIFT_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                closeFlag: false
            }
        case UPDATE_GIFT_CARD_SUCCESS:
            const cloneGiftCardLists = state.giftCardLists
            let index1 = cloneGiftCardLists.findIndex(({ _id }) => _id === action.userData._id)
            if (index1 !== -1) {
                cloneGiftCardLists[index1] = action.userData
            }
            return {
                ...state,
                userDetails: action.userData,
                giftCardLists: cloneGiftCardLists,
                loading: false,
                isSuccess: true,
                status: 'successful',
                closeFlag: true
            }

        case UPDATE_GIFT_CARD_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                closeFlag: false
            }
        case DELETE_GIFT_CARD_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                closeFlag: false
            }
        case DELETE_GIFT_CARD_SUCCESS: {
            return {
                ...state,
                deletedCard: action.categoryData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                closeFlag: true,
            }
        }
        case DELETE_GIFT_CARD_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                closeFlag: false
            }

        default:
            return state
    }
}
export default giftCard
