import {
    ADD_SALES_TAX_REQUEST,
    ADD_SALES_TAX_SUCCESS,
    ADD_SALES_TAX_ERROR,
    UPDATE_SALES_TAX_REQUEST,
    UPDATE_SALES_TAX_SUCCESS,
    UPDATE_SALES_TAX_ERROR,
    GET_SALES_TAX_REQUEST,
    GET_SALES_TAX_SUCCESS,
    GET_SALES_TAX_ERROR,
    CLEAR_MESSAGES,
    ASSIGN_PRODUCT_SALES_TAX_REQUEST,
    ASSIGN_PRODUCT_SALES_TAX_SUCCESS,
    ASSIGN_PRODUCT_SALES_TAX_ERROR,

} from '../constants/salesTaxConstant';

const initialState = {
    status: '',
    loading: true,
    isSuccess: false,
    errorMessage: "",
    salesTaxLists: [],
    selectedSalesTaxItem: [],
    message: '',
    addedSalesTax: '',
    flag: '',
    assignProduct: "",
    addSale: false,
    addFlag: false

}

const salesTax = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES_TAX_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                isSuccess: false,
            }
        case GET_SALES_TAX_SUCCESS: {
            return {
                ...state,
                salesTaxLists: action.salesTaxData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg
            }
        }
        case GET_SALES_TAX_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.msg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg,
                isSuccess: false,
            }
        case ADD_SALES_TAX_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: 'api requested',
                flag: '',
                addFlag: false

            }
        case ADD_SALES_TAX_SUCCESS: {
            const addTax = action.salesTax
            const list = state.salesTaxLists
            list.push(addTax)
            return {
                ...state,
                loading: false,
                isSuccess: true,
                status: 'successful',
                salesTaxLists: list,
                addedSalesTax: action.salesTax,
                message: action.msg,
                flag: action.msg,
                addFlag: true
            }
        }
        case ADD_SALES_TAX_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
                message: action.msg,
                flag: action.msg,
                addFlag: false


            }
        case CLEAR_MESSAGES:
            return {
                ...state,
                flag: '',
                addFlag: false
            }
        case UPDATE_SALES_TAX_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                addSale: false,
                addFlag: false
            }
        case UPDATE_SALES_TAX_SUCCESS: {
            return {
                ...state,
                updatedSalesData: action.salesTax,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg,
                addSale: true,
                addFlag: true,
            }
        }
        case UPDATE_SALES_TAX_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg,
                addSale: false,
                addFlag: false,
            }
        case ASSIGN_PRODUCT_SALES_TAX_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: 'api requested',
                addSale: false,
                addFlag: false,
            }
        case ASSIGN_PRODUCT_SALES_TAX_SUCCESS: {
            let cloneData = state.salesTaxLists
            const index = cloneData.findIndex(({ _id }) => _id === action.assignProduct._id)
            cloneData[index] = action.assignProduct
            return {
                ...state,
                assignProduct: action.assignProduct,
                salesTaxLists: cloneData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg,
                addSale: true,
                addFlag: true
            }
        }
        case ASSIGN_PRODUCT_SALES_TAX_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg,
                addSale: false,
                addFlag: false
            }

        default:
            return state
    }
}
export default salesTax
