import {
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    EDIT_ORDER_REQUEST,
    EDIT_ORDER_SUCCESS,
    EDIT_ORDER_ERROR,
    CLEAR_FLAGS,
    ORDER_STATUS,
    UNSEEN_ORDERS,
    SET_ORDER_ID
} from '../constants/orderConstant';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    orderData: [],
    orderList: [],
    message: '',
    orderLists: [],
    unseenOrderList: [],
    cat_Flag: false,
    isNotification: false,
    orderId: ""
}

const order = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: ''
            }
        case GET_ORDER_SUCCESS: {

            return {
                ...state,
                totalUnseenOrders: action.unseen,
                orderLists: action.orderData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg

            }
        }
        case GET_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg
            }
        case EDIT_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                cat_Flag: false,
            }
        case EDIT_ORDER_SUCCESS: {
            const index = state.orderLists.findIndex(({ _id }) => _id === action.id)
            let data = state.orderLists
            const orderStatus = Object.keys(ORDER_STATUS).find(k => ORDER_STATUS[k] === action.status);
            data[index].status = orderStatus
            return {
                ...state,
                orderLists: data,
                loading: false,
                isSuccess: true,
                status: 'successful',
                cat_Flag: true
            }
        }
        case EDIT_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                isSuccess: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                cat_Flag: false
            }
        case CLEAR_FLAGS:
            return {
                ...state,
                cat_Flag: false
            }
        case UNSEEN_ORDERS:
            return {
                ...state,
                unseenOrderList: action.payload
            }
        case SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload
            }
        default:
            return state
    }
}
export default order
