/* eslint-disable */
import axios from "axios";
import history from '../utils/history';
import { isLoggedIn, loggedOutUser } from "../utils/common";
import store from "../reduxStore";
export default (endpoint, method, body, header, token, isExternal = false) => new Promise((resolve, reject) => {

    /**
     * url
     */
    let url = process.env.REACT_APP_API_SERVER_URL;
    // If production then we need to set the API Endpoint URL Here otherwise it
    // is being set from src/setupProxy.js File
    if (process.env.NODE_ENV === "production") {
        url = process.env.REACT_APP_API_SERVER_URL;
    }
    url += process.env.REACT_APP_API_BASE_URL + endpoint;

    /**
     * setHeaders
     */
    const isLogged = isLoggedIn()
    const token = (isLogged && isLogged.token)

    let setHeaders = { "Content-Type": "application/json" };
    if (header) {
        setHeaders = { Authorization: "bearer " + token };
    }
    /**
     * axios request
     */
    axios({
        method,
        url,
        data: body,
        headers: setHeaders,
    }).then(res => {
        const { data, message, statusCode } = res?.data
        let status = false
        if (statusCode === 200) {
            status = true
        }
        resolve({ data, message, status })
    }).catch((err) => {
        if (err?.response?.data?.statusCode === 401) {
            const { data, message } = err?.response?.data
            loggedOutUser()
            history.push('/login')
            window.location.reload();
            // store.dispatch({ type:"LOG_OUT"})
            resolve({ data, message, status: false })
        } else {
            try {
                const { data, message } = err?.response?.data
                resolve({ data, message, status: false })
            } catch (error) {
                console.log(`error`, err)
            }
        }
    });
})
