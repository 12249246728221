export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR"

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS"
export const LOGOUT_REQUEST_ERROR = "LOGOUT_REQUEST_ERROR"

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST"
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS"

export const SIGN_UP_REQUEST_ERROR = "SIGN_UP_REQUEST_ERROR"

export const USER_FILTER_BEGIN = "USER_FILTER_BEGIN"
export const USER_FILTER_SUCCESS = "USER_FILTER_SUCCESS"
export const USER_FILTER_ERROR = "USER_FILTER_ERROR"

export const FILTER_USERLIST = "FILTER_USERLIST"
export const SET_DEVICE_TOKEN = "SET_DEVICE_TOKEN"