/* eslint-disable */
import { combineReducers } from 'redux';
import user from './user';
import toast from './toast';
import category from './category'
import store from './storeReducer'
import modifier from './modifierReducer'
import product from './productReducer'
import salesTax from './salesTaxReducer'
import loader from './loaderReducer'
import setting from './settingReducer'
import giftCard from "./giftCardReducer"
import discount from "./discountReducer"
import order from "./orderReducer"
import { loggedOutUser } from '../utils/common';
import history from "../utils/history"
import giftCardTemplate from './giftCardTemplateReducer';


const initialState = {}
const logout = (state = initialState, action) => {
    switch (action.type) {
        case "LOG_OUT":
            loggedOutUser()
            history.push('/login')
        default:
            return state
    }
}

export default combineReducers({
    user: user,
    toast: toast,
    loader: loader,
    category: category,
    store: store,
    modifier: modifier,
    product: product,
    salesTax: salesTax,
    setting: setting,
    giftCard: giftCard,
    giftCardTemplate: giftCardTemplate,
    discount: discount,
    order: order,
    logout
});
