import {

    UPDATE_GIFT_CARD_TEMPLATE_REQUEST,
    UPDATE_GIFT_CARD_TEMPLATE_SUCCESS,
    UPDATE_GIFT_CARD_TEMPLATE_ERROR,
    GET_GIFT_CARD_TEMPLATE_REQUEST,
    GET_GIFT_CARD_TEMPLATE_SUCCESS,
    GET_GIFT_CARD_TEMPLATE_ERROR,
    CLEAR_FLAGS,

} from '../constants/giftCardTemplateConstant';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    giftCardTemplateDetails: {}
}

const giftCardTemplate = (state = initialState, action) => {
    switch (action.type) {
        case GET_GIFT_CARD_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                errorMessage: ''
            }
        case GET_GIFT_CARD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.message,
                giftCardTemplateDetails: action.payload
            }

        case GET_GIFT_CARD_TEMPLATE_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }

        case UPDATE_GIFT_CARD_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                closeFlag: false
            }
        case UPDATE_GIFT_CARD_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isSuccess: true,
                status: 'successful',
                closeFlag: true
            }

        case UPDATE_GIFT_CARD_TEMPLATE_ERROR:
            return {
                ...state,
                loading: false,
                status: 'fail',
                closeFlag: false
            }

        case CLEAR_FLAGS:
            return {
                ...state,
                closeFlag: false
            }

        default:
            return state
    }
}
export default giftCardTemplate
