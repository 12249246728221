import { LOADER } from '../constants/common';
export const initialState = {
    status: false
};
const loader = (state = initialState, action) => {
    switch (action.type) {
        case LOADER:
            return {
                ...state,
                status:action.payload 
            };
        default:
            return state;
    }
};
export default loader;
