import {
    // ADD_SETTING_REQUEST,
    // ADD_SETTING_SUCCESS,
    // ADD_SETTING_ERROR,
    UPDATE_SETTING_REQUEST,
    UPDATE_SETTING_SUCCESS,
    UPDATE_SETTING_ERROR,
    GET_SETTING_REQUEST,
    GET_SETTING_SUCCESS,
    GET_SETTING_ERROR,
} from '../constants/settingConstant';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    settingLists: {},
    editedData:[],
    message:'',
}

const setting = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message:''
            }
        case GET_SETTING_SUCCESS: {

            return {
                ...state,
                settingLists: action.settingData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message:action.msg
            }
        }
        case GET_SETTING_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage:action.msg || "Something went wrong please try again",
                status: 'fail',
                message:action.msg
            }
        
        case UPDATE_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }
        case UPDATE_SETTING_SUCCESS: {
            return {
                ...state,
                loading: false,
                isSuccess: true,
                settingLists: action.settingData,
                status: 'successful',
                message:action.msg
            }
        }
        case UPDATE_SETTING_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.msg || "Something went wrong please try again",
                status: 'fail',
                message:action.msg
            }
       
        default:
            return state
    }
}
export default setting;
