import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toastStatusChange } from '../actions/toastAction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ToastSnackbar() {

    const dispatch = useDispatch();
    const toast = useSelector(state => state.toast);

    const handleClose = () => {
        dispatch(toastStatusChange());
    };

    return (
        <Fragment>
            {
                toast.status &&
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={toast.status}
                    autoHideDuration={5000}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={toast.type}>
                        {toast.message}
                    </Alert>
                </Snackbar>
            }
        </Fragment>)
}