export const ADD_GIFT_CARD_CATEGORY_REQUEST ="ADD_GIFT_CARD_CATEGORY_REQUEST"
export const ADD_GIFT_CARD_CATEGORY_SUCCESS = "ADD_GIFT_CARD_CATEGORY_SUCCESS"
export const ADD_GIFT_CARD_CATEGORY_ERROR = "ADD_GIFT_CARD_CATEGORY_ERROR"

export const DELETE_GIFT_CARD_CATEGORY_REQUEST =  "DELETE_GIFT_CARD_CATEGORY__REQUEST"
export const DELETE_GIFT_CARD_CATEGORY_SUCCESS =  "DELETE_GIFT_CARD_CATEGORY_SUCCESS"
export const DELETE_GIFT_CARD_CATEGORY_ERROR =  "DELETE_GIFT_CARD_CATEGORY_ERROR"

export const UPDATE_GIFT_CARD_CATEGORY_REQUEST =  "UPDATE_GIFT_CARD_CATEGORY_REQUEST"
export const UPDATE_GIFT_CARD_CATEGORY_SUCCESS =  "UPDATE_GIFT_CARD_CATEGORY_SUCCESS"
export const UPDATE_GIFT_CARD_CATEGORY_ERROR =  "UPDATE_GIFT_CARD_CATEGORY_ERROR"

export const GET_GIFT_CARD_CATEGORY_REQUEST ="GET_GIFT_CARD_CATEGORY_REQUEST"
export const GET_GIFT_CARD_CATEGORY_SUCCESS = "GET_GIFT_CARD_CATEGORY_SUCCESS"
export const GET_GIFT_CARD_CATEGORY_ERROR = "GET_GIFT_CARD_CATEGORY_ERROR"

export const ADD_GIFT_CARD_REQUEST ="ADD_GIFT_CARD_REQUEST"
export const ADD_GIFT_CARD_SUCCESS = "ADD_GIFT_CARD_SUCCESS"
export const ADD_GIFT_CARD_ERROR = "ADD_GIFT_CARD_ERROR"

export const DELETE_GIFT_CARD_REQUEST =  "DELETE_GIFT_CARD__REQUEST"
export const DELETE_GIFT_CARD_SUCCESS =  "DELETE_GIFT_CARD_SUCCESS"
export const DELETE_GIFT_CARD_ERROR =  "DELETE_GIFT_CARD_ERROR"

export const UPDATE_GIFT_CARD_REQUEST =  "UPDATE_GIFT_CARD_REQUEST"
export const UPDATE_GIFT_CARD_SUCCESS =  "UPDATE_GIFT_CARD_SUCCESS"
export const UPDATE_GIFT_CARD_ERROR =  "UPDATE_GIFT_CARD_ERROR"

export const GET_GIFT_CARD_REQUEST ="GET_GIFT_CARD_REQUEST"
export const GET_GIFT_CARD_SUCCESS = "GET_GIFT_CARD_SUCCESS"
export const GET_GIFT_CARD_ERROR = "GET_GIFT_CARD_ERROR"

export const CLEAR_FLAGS = "CLEAR_FLAGS"

