import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    USER_FILTER_BEGIN,
    USER_FILTER_SUCCESS,
    USER_FILTER_ERROR,
    FILTER_USERLIST,
    SET_DEVICE_TOKEN
} from "../constants/user";

const initialState = {
    loading: false,
    isSuccess: false,
    errorMessage: "",
    deviceToken: "",
    userDetails: {},
    clonedUserDetails: {}
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOGIN_REQUEST_SUCCESS: {
            const { userData } = action.payload
            return {
                ...state,
                userDetails: userData,
                loading: false,
                isSuccess: true
            }
        }
        case LOGIN_REQUEST_ERROR:
            const { errorMsg } = action.payload
            return {
                ...state,
                loading: false,
                errorMessage: errorMsg || "Something went wrong please try again",
                userDetails: {}
            }
        case USER_FILTER_BEGIN:
            return {
                ...state,
                loading: true
            }
        case USER_FILTER_SUCCESS: {
            return {
                ...state,
                userDetails: action.userFilterData,
                clonedUserDetails: action.userFilterData,
                loading: false,
                isSuccess: true
            }
        }
        case USER_FILTER_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                userDetails: {}
            }

        case FILTER_USERLIST: {
            const searchValue = action.payload;
            let filterUserData = state.userDetails;
            if (searchValue) {
                filterUserData = Object.values(filterUserData).filter(data => {
                    return (
                        (data && data.firstName && data.firstName.toLowerCase().search(searchValue.toLowerCase()) !== -1) ||
                        (data && data.lastName && data.lastName.toLowerCase().search(searchValue.toLowerCase()) !== -1)
                    )
                })
            } else {
                filterUserData = state.clonedUserDetails
            }
            return {
                ...state,
                userDetails: filterUserData
            }
        }
        case SET_DEVICE_TOKEN:
            return {
                ...state,
                deviceToken: action.payload
            }
        default:
            return state
    }
}
export default user
