
export const GET_ORDER_REQUEST = "GET_ORDER__REQUEST"
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"
export const GET_ORDER_ERROR = "GET_ORDER_ERROR"

export const EDIT_ORDER_REQUEST = "EDIT_ORDER__REQUEST"
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS"
export const EDIT_ORDER_ERROR = "EDIT_ORDER_ERROR"

export const CLEAR_FLAGS = "CLEAR_FLAGS"

export const ORDER_TYPES = {
    pickUp: 1,
    delivery: 2,
    inDine: 3
}

export const ORDER_STATUS = {
    Initiated: 1,
    InProgress: 2,
    Ready: 3,
    PickedUp: 4,
    Failed: 5
}

export const PAYMENT_STATUS = {
    InProgress: 1,
    Compelted: 2,
    Failed: 3
}

export const UNSEEN_ORDERS = "UNSEEN_ORDERS"

export const SET_ORDER_ID = "SET_ORDER_ID"