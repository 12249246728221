import {
    ADD_MODIFIER_REQUEST,
    ADD_MODIFIER_SUCCESS,
    ADD_MODIFIER_ERROR,
    EDIT_MODIFIER_REQUEST,
    EDIT_MODIFIER_SUCCESS,
    EDIT_MODIFIER_ERROR,
    UPDATE_MODIFIER_REQUEST,
    UPDATE_MODIFIER_SUCCESS,
    CLEAR_UPDATE_MODIFIER,
    UPDATE_MODIFIER_ERROR,
    GET_MODIFIER_REQUEST,
    GET_MODIFIER_SUCCESS,
    GET_MODIFIER_ERROR,
    ONCHANGE_MODIFIER_DATA,
    ONCHANGE_ADD_MODIFIER_DATA,
    SET_SELECTED_MODIFIER_SUCCESS,
    SET_SELECTED_MODIFIER_REQUEST,
    DELETE_MODIFIER_REQUEST,
    DELETE_MODIFIER_SUCCESS,
    DELETE_MODIFIER_ERROR,

} from '../constants/modifierConstant';

const initialState = {
    status: '',
    modifierStatus: '',
    loading: true,
    isSuccess: false,
    errorMessage: "",
    modifierDataLists: [],
    selectModifierData: [],
    addedModifierData: {},
    updatedModifierData: [],
    addData: true,
    addHandleChange: {
        "setName": "",

        "allowMultipleSelection": false,
        "isForFutureLocation": false,
        "modifiers": [
            {
                "name": "",
                "price": ""
            }
        ]
    },
    addModifierFlag: false
}

function insertItemImHelper(allData, action) {
    let ind = allData.findIndex(p => p._id === action._id)
    const newArray = allData.slice()
    newArray[ind] = action
    return newArray
}

function addItemImHelper(allData, ModifierData) {
    const cloneData = allData.slice()
    cloneData.push(ModifierData)
    return cloneData
}

const modifier = (state = initialState, action) => {
    switch (action.type) {
        case GET_MODIFIER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }
        case GET_MODIFIER_SUCCESS: {
            return {
                ...state,
                modifierDataLists: action.modifierData,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }
        }
        case GET_MODIFIER_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }
        case ADD_MODIFIER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                addData: false,
                addModifierFlag: false
            }
        case ADD_MODIFIER_SUCCESS: {
            const { userData } = action
            return {
                ...state,
                addedModifierData: userData,
                modifierDataLists: addItemImHelper(state.modifierDataLists, userData),
                loading: false,
                isSuccess: true,
                status: 'successful',
                addData: true,
                addModifierFlag: true
            }
        }
        case ADD_MODIFIER_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
                addData: true,
                addModifierFlag: false

            }
        case DELETE_MODIFIER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'request',
                addData: false,
            }
        case DELETE_MODIFIER_SUCCESS: {
            let cloneModifierDataLists = state.modifierDataLists.slice()
            let index = cloneModifierDataLists.findIndex(({ _id }) => _id === action.modifierData)
            if (index !== -1) {
                cloneModifierDataLists.splice(index, 1)
            }
            return {
                ...state,
                loading: false,
                isSuccess: true,
                status: 'deleted',
                modifierDataLists: cloneModifierDataLists
            }
        }
        case DELETE_MODIFIER_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: "Something went wrong please try again",
                status: 'fail',
            }
        case UPDATE_MODIFIER_REQUEST:
            return {
                ...state,
                modifierStatus: 'requested',
                addData: false
            }
        case UPDATE_MODIFIER_SUCCESS: {
            return {
                ...state,
                updatedModifierData: action.modifierData,
                modifierDataLists: insertItemImHelper(state.modifierDataLists, action.modifierData),
                isSuccess: true,
                modifierStatus: 'successful',
                addData: false
            }
        }
        case UPDATE_MODIFIER_ERROR:
            return {
                ...state,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                modifierStatus: 'fail',
            }
        case CLEAR_UPDATE_MODIFIER:
            return {
                ...state,
                loading: false,
                modifierStatus: '',
                addModifierFlag: false,
                status:""
            }
        case EDIT_MODIFIER_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }
        case EDIT_MODIFIER_SUCCESS: {

            return {
                ...state,
                selectStoreData: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }
        }
        case EDIT_MODIFIER_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
            }
        case ONCHANGE_MODIFIER_DATA: {

            return {
                ...state,
                selectModifierData: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }
        }
        case ONCHANGE_ADD_MODIFIER_DATA: {

            return {
                ...state,
                addHandleChange: action.payload,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }
        }
        case SET_SELECTED_MODIFIER_REQUEST:
            return {
                ...state,
                status: 'requested',

            }
        case SET_SELECTED_MODIFIER_SUCCESS: {
            return {
                ...state,
                selectModifierData: action.payload,
                isSuccess: true,
                status: 'successful',
            }
        }
        default:
            return state
    }
}
export default modifier
