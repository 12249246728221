/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../utils/common";

export const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const isLogged = isLoggedIn()
    return (
        <Route
            {...rest}
            render={props =>
                (isLogged && isLogged.token) ?
                    (<Component {...props} />) :
                    (<Redirect to={{ pathname: "/login", location }} />)
            }
        />
    );
};