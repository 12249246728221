import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    USER_FILTER_BEGIN,
    USER_FILTER_SUCCESS,
    USER_FILTER_ERROR,
    FILTER_USERLIST,
    SET_DEVICE_TOKEN
} from '../constants/user';
import api from "../api/index";
import { login } from '../api/userApi';
import { LOADER } from "../constants/common";
import { toastStatusChange } from './toastAction';
export const loginUser = () => {
    return async (dispatch) => {
        dispatch({ type: LOGIN_REQUEST });
        dispatch({ type: LOADER, payload: true })
        try {
            const res = await login();
            if (res.data) {
                dispatch(toastStatusChange({ message: res.message, type: 'success', status: true }));
                dispatch({
                    type: LOGIN_REQUEST_SUCCESS,
                    userData: res.data
                });
            } else {
                dispatch(toastStatusChange({ message: res.message, type: 'error', status: true }));
                dispatch({ type: LOGIN_REQUEST_ERROR, errorMsg: res.message });
            }
            dispatch({ type: LOADER, payload: false })

        } catch (error) {
            dispatch(toastStatusChange({ message: error.message, type: 'error', status: true }));
            dispatch({ type: LOGIN_REQUEST_ERROR });
            dispatch({ type: LOADER, payload: false })

        }
    }
};


export const getUserList = (body) => {
    return async (dispatch) => {
        dispatch({ type: USER_FILTER_BEGIN });
        dispatch({ type: LOADER, payload: true })
        try {
            const res = await api('admin/users/list', 'POST', body, true)
            if (res.data) {
                // dispatch(toastStatusChange({ message: res.message, type: 'success', status: true }));
                dispatch({
                    type: USER_FILTER_SUCCESS,
                    userFilterData: res.data,
                    message: res.message
                });
            } else {
                dispatch({
                    type: USER_FILTER_ERROR,
                    errorMssg: res.message
                });
                dispatch(toastStatusChange({ message: res.message, type: 'error', status: true }));

            }
            dispatch({ type: LOADER, payload: false })

        } catch (error) {
            dispatch({ type: USER_FILTER_ERROR });
            dispatch({ type: LOADER, payload: false })
            dispatch(toastStatusChange({ message: error.message, type: 'error', status: true }));

        }
    }
};

export const filterUserList = value => {
    return {
        type: FILTER_USERLIST,
        payload: value
    }
}

export const setDeviceToken = token => {
    return async (dispatch) => {
        dispatch({
            type: SET_DEVICE_TOKEN,
            payload: token
        })
    }
}
