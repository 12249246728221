export const GET_PRODUCT_REQUEST =  "GET_PRODUCT_REQUEST"
export const GET_PRODUCT_SUCCESS =  "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_ERROR =  "GET_PRODUCT_ERROR"

export const ADD_PRODUCT_REQUEST =  "ADD_PRODUCT_REQUEST"
export const ADD_PRODUCT_SUCCESS =  "ADD_PRODUCT_SUCCESS"
export const ADD_PRODUCT_ERROR =  "ADD_PRODUCT_ERROR"

export const EDIT_PRODUCT_REQUEST =  "EDIT_PRODUCT_REQUEST"
export const EDIT_PRODUCT_SUCCESS =  "EDIT_PRODUCT_SUCCESS"
export const EDIT_PRODUCT_ERROR =  "EDIT_PRODUCT_ERROR"

export const CLEAR_PRODUCT_STATE =  "CLEAR_PRODUCT_STATE"