/* eslint-disable */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from './components/NotFound';
import ToastSnackbar from './components/ToastSnackbar';
import Loader from './components/Loader';
import { PrivateRoute } from './routes/PrivateRoute';
import GlobalLoader from "./components/globleLoader";
import history from './utils/history';
// import { ToastContainer, toast } from 'react-toastify';
import { getToken, onBackgroundListener1, onMessageListener } from './firebase';
import { useDispatch } from 'react-redux';
import { setOrderId } from './actions/orderAction';
const Login = lazy(() => import('./containers/auth/Login'));
const SignUp = lazy(() => import('./containers/auth/SignUp'));
const ForgotPassword = lazy(() => import('./containers/auth/ForgotPassword'));
const HomeRoutes = lazy(() => import('./containers/HomeRoutes'))
const About = lazy(() => import('./containers/About'));

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch()
  getToken(setTokenFound, dispatch);

  onMessageListener().then(payload => {
    dispatch(setOrderId(payload.data.orderId))
  }).catch(err => console.log('failed: ', err));
  return (
    <Router history={history}>
      {/* <MenuAppBar /> */}
      <ToastSnackbar />
      <GlobalLoader />
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute exact path="/" component={HomeRoutes} />
          {/* <Route path="/home" component={Home} /> */}
          <Route path="/home" component={HomeRoutes} />
          <Route path="/about" component={About} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/signup" component={SignUp} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
