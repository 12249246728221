
export const ADD_SALES_TAX_REQUEST ="ADD_SALES_TAX_REQUEST"
export const ADD_SALES_TAX_SUCCESS = "ADD_SALES_TAX_SUCCESS"
export const ADD_SALES_TAX_ERROR = "ADD_SALES_TAX_ERROR"

export const GET_SALES_TAX_REQUEST =  "GET_SALES_TAX_REQUEST"
export const GET_SALES_TAX_SUCCESS =  "GET_SALES_TAX_SUCCESS"
export const GET_SALES_TAX_ERROR =  "GET_SALES_TAX_ERROR"

export const EDIT_SALES_TAX_REQUEST =  "EDIT_SALES_TAX_REQUEST"
export const EDIT_SALES_TAX_SUCCESS =  "EDIT_SALES_TAX_SUCCESS"
export const EDIT_SALES_TAX_ERROR =  "EDIT_SALES_TAX_ERROR"

export const UPDATE_SALES_TAX_REQUEST =  "UPDATE_SALES_TAX_REQUEST"
export const UPDATE_SALES_TAX_SUCCESS =  "UPDATE_SALES_TAX_SUCCESS"
export const UPDATE_SALES_TAX_ERROR =  "UPDATE_SALES_TAX_ERROR"

export const CLEAR_MESSAGES = "CLEAR_MESSAGES"

export const ASSIGN_PRODUCT_SALES_TAX_REQUEST =  "ASSIGN_PRODUCT_SALES_TAX_REQUEST"
export const ASSIGN_PRODUCT_SALES_TAX_SUCCESS =  "ASSIGN_PRODUCT_SALES_TAX_SUCCESS"
export const ASSIGN_PRODUCT_SALES_TAX_ERROR =  "ASSIGN_PRODUCT_SALES_TAX_ERROR"
