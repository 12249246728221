import {
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    EDIT_ORDER_REQUEST,
    EDIT_ORDER_SUCCESS,
    EDIT_ORDER_ERROR,
    CLEAR_FLAGS,
    UNSEEN_ORDERS,
    SET_ORDER_ID
} from '../constants/orderConstant';
import api from "../api/index";
import { LOADER } from '../constants/common';

import { toastStatusChange } from './toastAction';
export const getOrder = (filterOptions) => {
    return async (dispatch) => {
        dispatch({ type: GET_ORDER_REQUEST });
        dispatch({ type: LOADER, payload: true })
        try {
            const res = await api(`admin/order`, 'Post', filterOptions, true);
            if (res.data) {
                // dispatch(toastStatusChange({ message: res.message, type: 'success', status: true }));
                dispatch({
                    type: GET_ORDER_SUCCESS,
                    orderData: res.data,
                    msg: res.message,
                    unseen: res.totalUnseenOrders
                });
            } else {
                dispatch(toastStatusChange({ message: res.message, type: 'error', status: true }));
                dispatch({ type: GET_ORDER_ERROR, errorMsg: res.message });
            }
            dispatch({ type: LOADER, payload: false })
        } catch (error) {
            dispatch(toastStatusChange({ message: error.message, type: 'error', status: true }));
            dispatch({ type: GET_ORDER_ERROR, msg: error.message });
            dispatch({ type: LOADER, payload: false })
        }
    }
};


export const editOrderStatus = (body) => {
    return async (dispatch) => {
        dispatch({ type: EDIT_ORDER_REQUEST });
        dispatch({ type: LOADER, payload: true })
        try {
            const res = await api(`admin/order/change-status`, 'POST', body, true);
            if (res.data) {
                dispatch(toastStatusChange({ message: res.message, type: 'success', status: true }));
                dispatch({
                    type: EDIT_ORDER_SUCCESS,
                    userData: res.data,
                    id: body.orderId,
                    status: body.status
                });
            } else {
                dispatch(toastStatusChange({ message: res.message, type: 'error', status: true }));
                dispatch({
                    type: EDIT_ORDER_ERROR,
                    msg: res.message
                });
            }
            dispatch({ type: LOADER, payload: false })
        } catch (error) {
            dispatch(toastStatusChange({ message: error.message, type: 'error', status: true }));
            dispatch({
                type: EDIT_ORDER_ERROR,
                msg: error.message
            });
            dispatch({ type: LOADER, payload: false })
        }
    }
};
export const clearFlags = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_FLAGS })
    }
}

export const setOrderId = (orderId) => {
    return async (dispatch) => {
        dispatch({ type: SET_ORDER_ID, payload: orderId })
    }
}

export const seenOrder = (data, id = null) => {
    return async (dispatch, getState) => {
        if (data.length === 0) {
            data = getState()?.order?.unseenOrderList
            console.log(`data`, data)
        }
        dispatch({ type: LOADER, payload: true })
        try {
            if (id) {
                await api("admin/order/seen/" + id, 'GET', null, true)
                let unseenOrds = data.filter(o => o._id !== id)
                dispatch({ type: UNSEEN_ORDERS, payload: unseenOrds })
            } else {
                dispatch({ type: UNSEEN_ORDERS, payload: data })
            }
            dispatch({ type: LOADER, payload: false })
        } catch (error) {
            dispatch({ type: LOADER, payload: false })
        }
    }
}
