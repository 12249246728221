import {
  ADD_DISCOUNT_REQUEST,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_ERROR,
  UPDATE_DISCOUNT_REQUEST,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
  GET_DISCOUNT_REQUEST,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_ERROR,
  DELETE_DISCOUNT_REQUEST,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_ERROR,
  CLEAR_FLAGS,
  SET_DISCOUNT_SUCCESS,
  SET_DISCOUNT_ERROR,
  SET_DISCOUNT_STATUS,
} from "../constants/discountConstant";

const initialState = {
  status: "",
  loading: false,
  isSuccess: false,
  errorMessage: "",
  discountLists: [],
  selectedDiscount: {},
  addedDiscount: {},
  updatedDiscount: {},
  message: "",
  closeFlag: false,
  deletedDiscount: {},
  isShow: false,
};

const discount = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        status: "requested",
        message: "",
        errorMessage: "",
      };
    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discountLists: action.discountData,
        loading: false,
        isSuccess: true,
        status: "successful",
        message: action.message,
      };

    case GET_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: "Something went wrong please try again",
        status: "fail",
      };
    case ADD_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        status: "requested",
        errorMessage: "",
        closeFlag: false,
      };
    case ADD_DISCOUNT_SUCCESS:
      const cloneData = [...state.discountLists];
      cloneData.push(action.discountData);
      return {
        ...state,
        addedDiscount: action.discountData,
        loading: false,
        discountLists: cloneData,
        isSuccess: true,
        status: "successful",
        closeFlag: true,
      };

    case ADD_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: "Something went wrong please try again",
        status: "fail",
        closeFlag: false,
        message: action.errorMsg,
      };
    case UPDATE_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        status: "requested",
        closeFlag: false,
      };
    case UPDATE_DISCOUNT_SUCCESS:
      const cloneDiscountLists = state.discountLists;
      let index = cloneDiscountLists.findIndex(
        ({ _id }) => _id === action.userData._id
      );
      if (index !== -1) {
        cloneDiscountLists[index] = action.userData;
      }
      return {
        ...state,
        updatedDiscount: action.userData,
        discountLists: cloneDiscountLists,
        loading: false,
        isSuccess: true,
        status: "successful",
        closeFlag: true,
      };

    case UPDATE_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        status: "fail",
        closeFlag: false,
      };
    case SET_DISCOUNT_STATUS:
      return {
        ...state,
        loading: true,
        status: "requested",
        closeFlag: false,
      };
    case SET_DISCOUNT_SUCCESS:
      const clonedDataList = state.discountLists;
      const ind = clonedDataList.findIndex(({ _id }) => _id === action.id);

      if (~ind) {
        clonedDataList[ind].isShow = action.isShow;
      }
      return {
        ...state,
        loading: false,
        status: "successful",
        isShow: action.isShow,
        closeFlag: true,
      };
    case SET_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        status: "fail",
        closeFlag: false,
      };
    case DELETE_DISCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        status: "requested",
        closeFlag: false,
      };
    case DELETE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        deletedCategory: action.discountData,
        loading: false,
        isSuccess: true,
        status: "successful",
        closeFlag: true,
      };
    }
    case DELETE_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        status: "fail",
        closeFlag: false,
      };
    case CLEAR_FLAGS:
      return {
        ...state,
        closeFlag: false,
      };
    default:
      return state;
  }
};
export default discount;
