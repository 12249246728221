
export const UPDATE_GIFT_CARD_TEMPLATE_REQUEST = "UPDATE_GIFT_CARD_TEMPLATE_REQUEST"
export const UPDATE_GIFT_CARD_TEMPLATE_SUCCESS = "UPDATE_GIFT_CARD_TEMPLATE_SUCCESS"
export const UPDATE_GIFT_CARD_TEMPLATE_ERROR = "UPDATE_GIFT_CARD_TEMPLATE_ERROR"

export const GET_GIFT_CARD_TEMPLATE_REQUEST = "GET_GIFT_CARD_TEMPLATE_REQUEST"
export const GET_GIFT_CARD_TEMPLATE_SUCCESS = "GET_GIFT_CARD_TEMPLATE_SUCCESS"
export const GET_GIFT_CARD_TEMPLATE_ERROR = "GET_GIFT_CARD_TEMPLATE_ERROR"

export const CLEAR_FLAGS = "CLEAR_FLAGS"

