export const GET_DISCOUNT_REQUEST = "GET_DISCOUNT_REQUEST";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_ERROR = "GET_DISCOUNT_ERROR";

export const ADD_DISCOUNT_REQUEST = "ADD_DISCOUNT_REQUEST";
export const ADD_DISCOUNT_SUCCESS = "ADD_DISCOUNT_SUCCESS";
export const ADD_DISCOUNT_ERROR = "ADD_DISCOUNT_ERROR";

export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_ERROR = "UPDATE_DISCOUNT_ERROR";

export const DELETE_DISCOUNT_REQUEST = "DELETE_DISCOUNT_REQUEST";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_ERROR = "DELETE_DISCOUNT_ERROR";

export const CLEAR_FLAGS = "CLEAR_FLAGS";

export const SET_DISCOUNT_STATUS = "SET_DISCOUNT_STATUS";
export const SET_DISCOUNT_SUCCESS = "SET_DISCOUNT_SUCCESS";
export const SET_DISCOUNT_ERROR = "SET_DISCOUNT_ERROR";
