export const ADD_MODIFIER_REQUEST ="ADD_MODIFIER_REQUEST"
export const ADD_MODIFIER_SUCCESS = "ADD_MODIFIER_SUCCESS"
export const ADD_MODIFIER_ERROR = "ADD_MODIFIER_ERROR"

export const EDIT_MODIFIER_REQUEST =  "EDIT_MODIFIER_REQUEST"
export const EDIT_MODIFIER_SUCCESS =  "EDIT_MODIFIER_SUCCESS"
export const EDIT_MODIFIER_ERROR =  "EDIT_MODIFIER_ERROR"

export const DELETE_MODIFIER_REQUEST =  "DELETE_MODIFIER__REQUEST"
export const DELETE_MODIFIER_SUCCESS =  "DELETE_MODIFIER_SUCCESS"
export const DELETE_MODIFIER_ERROR =  "DELETE_MODIFIER_ERROR"

export const UPDATE_MODIFIER_REQUEST =  "UPDATE_MODIFIER_REQUEST"
export const UPDATE_MODIFIER_SUCCESS =  "UPDATE_MODIFIER_SUCCESS"
export const UPDATE_MODIFIER_ERROR =  "UPDATE_MODIFIER_ERROR"
export const CLEAR_UPDATE_MODIFIER =  "CLEAR_UPDATE_MODIFIER"

export const ONCHANGE_MODIFIER_DATA = "ONCHANGE_MODIFIER_DATA"
export const ONCHANGE_ADD_MODIFIER_DATA = "ONCHANGE_ADD_MODIFIER_DATA"

export const GET_MODIFIER_REQUEST =  "GET_MODIFIER__REQUEST"
export const GET_MODIFIER_SUCCESS =  "GET_MODIFIER_SUCCESS"
export const GET_MODIFIER_ERROR =  "GET_MODIFIER_ERROR"

export const SET_SELECTED_MODIFIER_REQUEST =  "SET_SELECTED_MODIFIER_REQUEST"
export const SET_SELECTED_MODIFIER_SUCCESS =  "SET_SELECTED_MODIFIER_SUCCESS"


