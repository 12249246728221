import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    root: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff",
        opacity: 0.6,
        zIndex: 3000
    },
    loding: {
        position: "absolute",
        textAlign: "center",
        top: "47%",
        left: "47%",
        zIndex: 1001
    },
  });

  
export default function Loader() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.loding}>
                <CircularProgress />
            </div>
        </div>
    );
}
