
export const ADD_STORE_REQUEST = "ADD_STORE__REQUEST"
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS"
export const ADD_STORE_ERROR = "ADD_STORE_ERROR"

export const GET_STORE_REQUEST = "GET_STORE__REQUEST"
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS"
export const GET_STORE_ERROR = "GET_STORE_ERROR"


export const EDIT_STORE_REQUEST = "EDIT_STORE__REQUEST"
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS"
export const EDIT_STORE_ERROR = "EDIT_STORE_ERROR"

export const DELETE_STORE_REQUEST = "DELETE_STORE__REQUEST"
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS"
export const DELETE_STORE_ERROR = "DELETE_STORE_ERROR"

export const UPDATE_STORE_REQUEST = "UPDATE_STORE__REQUEST"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_ERROR = "UPDATE_STORE_ERROR"

export const ONCHANGE_STORE_DATA = "ONCHANGE_STORE_DATA"
export const ONCHANGE_ADD_STORE_DATA = "ONCHANGE_ADD_STORE_DATA"

export const CHANGE_STORE_CREDENTIAL_REQUEST = "CHANGE_STORE_CREDENTIAL_REQUEST"
export const CHANGE_STORE_CREDENTIAL_SUCCESS = "CHANGE_STORE_CREDENTIAL_SUCCESS"
export const CHANGE_STORE_CREDENTIAL_ERROR = "CHANGE_STORE_CREDENTIAL_ERROR"

export const CLEAR_FLAGS = "CLEAR_FLAGS"


