import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        height: "100%",
        width: "100%",
        backgroundColor: "#ffffff",
        opacity: 0.6,
        zIndex: 3000
    },
    loding: {
        position: "absolute",
        textAlign: "center",
        top: "47%",
        left: "47%",
        zIndex: 1001
    },
});


export default function GlobalLoader() {
    const classes = useStyles();
    const loader = useSelector(state => state.loader.status);
    return (
        <React.Fragment>
            {
                loader && <div className={classes.root}>
                    <div className={classes.loding}>
                        <CircularProgress />
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
