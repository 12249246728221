import firebase from 'firebase/app';
import 'firebase/messaging';
import { setDeviceToken } from './actions/userActions';

const firebaseConfig = {
  apiKey: "AIzaSyB_gy6vyr-kbPQqrjW_8OoBtpHfFUSHnks",
  authDomain: "coffee-roaster-3a9f6.firebaseapp.com",
  databaseURL: "https://coffee-roaster-3a9f6.firebaseio.com",
  projectId: "coffee-roaster-3a9f6",
  storageBucket: "coffee-roaster-3a9f6.appspot.com",
  messagingSenderId: "636182121975",
  appId: "1:636182121975:web:56638a0d29fdd586d132f2",
  measurementId: "G-N7V1CR8RKY"
};

firebase.initializeApp(firebaseConfig);
let messaging = null
try {
  messaging = firebase.messaging();
} catch (error) {
  console.log(`error`, error)
}


export const getToken = (setTokenFound, dispatch) => {
  return messaging?.getToken({ vapidKey: 'BASlplCe8FhLx-psfdNy3By_rqlm4waXR22SsJVrH54ur8tOGpqr8tKkjsr0z4oyLKU5SdJ-gQBE-5HygMpCtvQ' }).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      dispatch(setDeviceToken(currentToken))
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload)
    });
  });
