export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY__REQUEST"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR"

export const GET_CATEGORY_REQUEST = "GET_CATEGORY__REQUEST"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR"

export const EDIT_CATEGORY_REQUEST = "EDIT_CATEGORY__REQUEST"
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS"
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR"

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY__REQUEST"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR"

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY__REQUEST"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR"


export const ASSIGN_PRODUCT_CATEGORY_REQUEST = "ASSIGN_PRODUCT_CATEGORY__REQUEST"
export const ASSIGN_PRODUCT_CATEGORY_SUCCESS = "ASSIGN_PRODUCT_CATEGORY_SUCCESS"
export const ASSIGN_PRODUCT_CATEGORY_ERROR = "ASSIGN_PRODUCT_CATEGORY_ERROR"

export const CLEAR_FLAGS = "CLEAR_FLAGS"
