import {
    GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_ERROR,
    EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, EDIT_PRODUCT_ERROR,
    ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_ERROR, CLEAR_PRODUCT_STATE
} from '../constants/productConstant';

const initialState = {
    status: '',
    isAddProductSuccess: false,
    loading: true,
    isSuccess: false,
    errorMessage: "",
    productLists: [],
    selectedProduct: [],
    message:''
}

const product = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message:''
            }
        case GET_PRODUCT_SUCCESS: {
            return {
                ...state,
                productLists: action.productLists,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message:action.msg
            }
        }
        case GET_PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
                status: 'fail',
                message:action.msg
            }

        case ADD_PRODUCT_REQUEST:
            return {
                ...state,
                isAddProductSuccess: false
            }
        case ADD_PRODUCT_SUCCESS: {
            const { productItem } = action
            return {
                ...state,
                productLists: [...state.productLists, productItem],
                isAddProductSuccess: true
            }
        }
        case ADD_PRODUCT_ERROR:
            return {
                ...state,
                isAddProductSuccess: false,
            }
        case EDIT_PRODUCT_REQUEST:
            return {
                ...state,
                isAddProductSuccess: false
            }
        case EDIT_PRODUCT_SUCCESS: {
            const { productItem } = action
            const ind = state.productLists.findIndex(k => k._id === productItem._id)
            const proList = [...state.productLists]
            proList[ind] = productItem
            return {
                ...state,
                productLists: proList,
                isAddProductSuccess: true
            }
        }
        case EDIT_PRODUCT_ERROR:
            return {
                ...state,
                isAddProductSuccess: false
            }
        case CLEAR_PRODUCT_STATE:
            return {
                ...state,
                isAddProductSuccess: false
            }
        default:
            return state
    }
}
export default product
