export const isLoggedIn = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return userData || {}
};

export function setLoggedUser(data) {
    const userData = JSON.stringify(data);
    localStorage.setItem('userData', userData);
}

export function loggedOutUser() {
    localStorage.removeItem('userData');
}