export const ADD_SETTING_REQUEST ="ADD_SETTING_REQUEST"
export const ADD_SETTING_SUCCESS = "ADD_SETTING_SUCCESS"
export const ADD_SETTING_ERROR = "ADD_SETTING_ERROR"

export const GET_SETTING_REQUEST ="GET_SETTING_REQUEST"
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS"
export const GET_SETTING_ERROR = "GET_SETTING_ERROR"

export const UPDATE_SETTING_REQUEST =  "UPDATE_SETTING_REQUEST"
export const UPDATE_SETTING_SUCCESS =  "UPDATE_SETTING_SUCCESS"
export const UPDATE_SETTING_ERROR =  "UPDATE_SETTING_ERROR"


