import {
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_ERROR,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_ERROR,
    EDIT_CATEGORY_REQUEST,
    EDIT_CATEGORY_SUCCESS,
    EDIT_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,
    ASSIGN_PRODUCT_CATEGORY_REQUEST,
    ASSIGN_PRODUCT_CATEGORY_SUCCESS,
    ASSIGN_PRODUCT_CATEGORY_ERROR,
    CLEAR_FLAGS,

} from '../constants/category';

const initialState = {
    status: '',
    loading: false,
    isSuccess: false,
    errorMessage: "",
    categoryData: [],
    categoryList: [],
    deletedCategory: [],
    message: '',
    assignProduct: [],
    categoryLists: [],
    cat_Flag: false,
}

const category = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: '',
                cat_Flag: false
            }
        case ADD_CATEGORY_SUCCESS: {
            const data = state.categoryLists
            data.push(action.categoryData)

            return {
                ...state,
                categoryLists: data,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg,
                cat_Flag: true

            }
        }
        case ADD_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                cat_Flag: false
            }
        case GET_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: ''
            }
        case GET_CATEGORY_SUCCESS: {

            return {
                ...state,
                categoryLists: action.categoryData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg

            }
        }
        case GET_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg
            }
        case EDIT_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                cat_Flag: false,
            }
        case EDIT_CATEGORY_SUCCESS: {
            const index = state.categoryLists.findIndex(({ _id }) => _id === action.id)
            const data = state.categoryLists
            data[index].categoryName = action.name
            return {
                ...state,
                categoryLists: data,
                loading: false,
                isSuccess: true,
                status: 'successful',
                cat_Flag: true
            }
        }
        case EDIT_CATEGORY_ERROR:
            return {
                ...state,

                loading: false,
                isSuccess: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                cat_Flag: false
            }
        case UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                cat_Flag: false,
            }
        case UPDATE_CATEGORY_SUCCESS: {
            const { userData } = action.payload
            return {
                ...state,
                userDetails: userData,
                loading: false,
                isSuccess: true,
                status: 'successful',
                cat_Flag: true,
            }
        }
        case UPDATE_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                cat_Flag: false
            }
        case DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
            }
        case DELETE_CATEGORY_SUCCESS: {
            return {
                ...state,
                deletedCategory: action.categoryData,
                loading: false,
                isSuccess: true,
                status: 'successful',
            }
        }
        case DELETE_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
            }
        case ASSIGN_PRODUCT_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                status: 'requested',
                message: 'api requested',
                cat_Flag: false

            }
        case ASSIGN_PRODUCT_CATEGORY_SUCCESS: {

            return {
                ...state,
                assignProduct: action.assignProduct,
                loading: false,
                isSuccess: true,
                status: 'successful',
                message: action.msg,
                cat_Flag: true
            }
        }
        case ASSIGN_PRODUCT_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                //  errorMessage: errorMsg || "Something went wrong please try again",
                status: 'fail',
                message: action.msg,
                cat_Flag: false
            }
        case CLEAR_FLAGS:
            return {
                ...state,
                cat_Flag: false
            }
        default:
            return state
    }
}
export default category
